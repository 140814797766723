if (document.querySelector(".sec-paralax-texto")) {
    window.onscroll = function() {
        let secsAnimations = document.querySelectorAll(".sec-paralax-texto .text-side article");
        let imgsAnimations = document.querySelectorAll(".sec-paralax-texto .img-side img");
        let articsAnimations = document.querySelector(".sec-paralax-texto");

        if (window.innerWidth > 900) {
            if (window.scrollY > articsAnimations.offsetTop - 100) {
                secsAnimations[1].classList.add("active-scroll");
                imgsAnimations[1].classList.add("active-scroll");
                secsAnimations[0].classList.remove("active-scroll");
                imgsAnimations[0].classList.remove("active-scroll");
            } else {
                secsAnimations[1].classList.remove("active-scroll");
                secsAnimations[0].classList.add("active-scroll");
                imgsAnimations[1].classList.remove("active-scroll");
                imgsAnimations[0].classList.add("active-scroll");
            }
        }
    };
}