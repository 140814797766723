const header = document.querySelector(".main-header-bundle");
const hoverToMenu = document.querySelector(".area-hover-to-menu");

if (window.innerWidth < 1200) {
    header.classList.remove("is-on-banner");
}

hoverToMenu.addEventListener("mouseover", () => {
    header.classList.remove("is-menu-hidden");
});
header.addEventListener("mouseover", () => {
    header.classList.remove("is-on-banner");
});
header.addEventListener("mouseleave", () => {

    if (window.scrollY < 50) {
        header.classList.add("is-on-banner");
        header.classList.remove("is-left-banner");
    } else {
        header.classList.add("is-left-banner");
        header.classList.remove("is-on-banner");
    }

});
var scrollAtual;


document.onscroll = () => {
    if (window.innerWidth > 1200) {
        if (window.scrollY > 500) {
            if (window.scrollY >= scrollAtual) {
                header.classList.add("is-menu-hidden");
            } else {
                header.classList.remove("is-menu-hidden");
            }
        } else {
            header.classList.remove("is-menu-hidden");
        }
        scrollAtual = window.scrollY;

    }

    if (window.innerWidth > 1200) {
        if (window.scrollY < 50) {
            header.classList.add("is-on-banner");
            header.classList.remove("is-left-banner");
        } else {
            header.classList.add("is-left-banner");
            header.classList.remove("is-on-banner");
        }
    }


    if (document.querySelector(".video_responsivo")) {
        const divVideo = document.querySelector(".video_responsivo");
        const alturaToDocument = document.querySelector(".page-fotos-e-videos").offsetTop;
        if (document.querySelector(".video_responsivo")) {
            if ((window.scrollY > (alturaToDocument + divVideo.offsetTop - 400)) && (window.scrollY < (divVideo.offsetHeight + alturaToDocument + divVideo.offsetTop - 200))) {
                // header.classList.add("is-menu-hidden");
            }
        }
    }
};

var selectLang = document.getElementById("lang-mobile");
selectLang.addEventListener("change", (event) => {
    window.location.replace(selectLang.value);
})

