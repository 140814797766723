// if (document.querySelector(".header-tela-zero")) {
//     var menuTelaZero = [
//         document.querySelector(".header-tela-zero"),
//         document.querySelectorAll(".open-close-menu"),
//         false,
//     ];

//     menuTelaZero[1].forEach(element => {
//         element.addEventListener("click", () => {
//             if (menuTelaZero[2] == false) {
//                 menuTelaZero[0].classList.add("header-menu-is-opened");
//                 menuTelaZero[2] = true;
//             } else {
//                 menuTelaZero[0].classList.remove("header-menu-is-opened");
//                 menuTelaZero[2] = false;
//             }
//         });
//     });
// }
if (document.querySelector(".header-tela-zero")) {
    var menuTelaZero = document.querySelector(".header-tela-zero");
    document.onscroll = () => {
        // if (window.innerWidth > 1200) {
        if (window.scrollY > 300) {
            menuTelaZero.classList.add("is-scroll-js");
        } else {
            menuTelaZero.classList.remove("is-scroll-js");
        }
    };

    var languegeTelaZero = document.querySelector(".header-tela-zero .language");
    var openTelaZero = document.querySelector(".header-tela-zero .language ul");

    openTelaZero.addEventListener("mouseover", () => {
        languegeTelaZero.classList.add("is-opened-language");
    });

    openTelaZero.addEventListener("mouseout", () => {
        languegeTelaZero.classList.remove("is-opened-language");
    });
}
