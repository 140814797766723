import $ from "jquery";
import datepicker from "jquery-ui/ui/widgets/datepicker.js";
import burger from "./modules/burger";
import FixedElementClearance from "./modules/FixedElementClearance";
import LazyLoad from "vanilla-lazyload";
import MenuToggle from "./modules/MenuToggle";
import SmoothScroll from "smooth-scroll/dist/smooth-scroll";
import motorReserva from "./modules/motor-reserva";
import "./modules/topo.js";
import "./modules/effects-scroll";
import "./modules/lightbox";
import "./modules/submenu-mobile";
import "./modules/menuTelaZero";
import trabalhe from "./modules/TrabalheConosco";
// Less used modules
//
// import accordion from "./modules/accordion";
// import DragonModal from "./modules/DragonModal";
// import DragonTabs from "./modules/DragonTabs";
import slides from "./modules/slides";

export default () => {
    motorReserva($, datepicker);
    burger();

    new LazyLoad({ elements_selector: ".lazy" });

    new SmoothScroll('a[href*="#"]', {
        header: ".main-header-bundle",
        speed: 1500,
        speedAsDuration: true,
        easing: "easeOutQuint",
    });

    new FixedElementClearance({
        element: ".js-get-main-header-height",
        CSSVariableName: "main-header-height",
    });

    new MenuToggle({
        menuToggle: [".js-main-menu-mobile-toggle"],
        menuElement: ".main-menu-mobile",
        menuClose: [".js-close-menu-mobile"],
        activeBodyClass: "mobile-menu-is-open",
        breakpointToHide: 1200,
    });

    slides();

    trabalhe();
};
