import Swiper from "swiper/dist/js/swiper.min.js";

export default () => {
    // Instâncias do Swiper aqui...
    //initialize swiper when document ready
    // var mySwiper = new Swiper(".fullbanner-slider", {
    //     slidesPerView: 1,
    //     loop: true,
    //     autoplay: {
    //         delay: 5000,
    //     },
    // });

    var mySwiperTre = new Swiper(".beira-mar-slider", {
        slidesPerView: 1,
        spaceBetween: 100,
        loop: true,
        loopedSlides: 2,
        watchOverflow: true,
        navigation: {
            nextEl: ".next-button",
            prevEl: ".back-button",
        },
        // centeredSlide: true,
        autoplay: {
            delay: 5000,
        },
        breakpoints: {
            // when window width is >= 320px
            1200: {
                spaceBetween: 10,
            },
        },
    });

    var quemSomosSlider = new Swiper(".quem-somos-slide", {
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        watchOverflow: true,
    });

    var premiacoesSlider = new Swiper(".premiacoes-slide", {
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        watchOverflow: true,
    });

    var luaDeMelSlider = new Swiper(".slider-experiencias-padrao", {
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        watchOverflow: true,
    });

    var SpaCarmelSlider = new Swiper(".spa-carmel-slide", {
        watchOverflow: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
    });

    var AcomodacoesSlider = new Swiper(".acomodacoes-slide", {
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        watchOverflow: true,
    });
};

var revistasSlider = new Swiper(".slide-revistas", {
    slidesPerView: 6,
    spaceBetween: 10,
    watchOverflow: true,
    pagination: {
        el: ".swiper-pagination-revistas",
        clickable: true,
    },
    // Responsive breakpoints
    breakpoints: {
        // when window width is >= 320px
        600: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        // when window width is >= 480px
        900: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
});

var fotosVideosSlider = new Swiper(".fotos-e-videos-slider", {
    slidesPerView: 1,
    watchOverflow: true,
    pagination: {
        el: ".swiper-pagination-fotos-e-videos",
        clickable: true,
    }
});
