export default ($, datepicker) => {

    if (document.querySelectorAll(".campo-form")) {
        $(function() {
            $("#data-chegada").datepicker({
                dateFormat: 'dd/mm/yy',
                dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
                dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
                dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
                monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                nextText: 'Próximo',
                prevText: 'Anterior',
                dateFormat: 'dd-mm-yy',
                minDate: 0,
                onSelect: function(date) {
                    $(".data-chegada").text(date);
                                  
                    $("#data-partida").datepicker('enable');
                    
                    if($("#data-partida").datepicker("getDate") != null) {
                        let dataEntrada = $("#data-chegada").datepicker("getDate").getFullYear().toString() +  ("00" + ($("#data-chegada").datepicker("getDate").getMonth() + 1)).slice(-2).toString() +  ("00" + $("#data-chegada").datepicker("getDate").getDate()).slice(-2).toString();
                        let dataPartida = $("#data-partida").datepicker("getDate").getFullYear().toString() +  ("00" + ($("#data-partida").datepicker("getDate").getMonth() + 1)).slice(-2).toString() +  ("00" + $("#data-partida").datepicker("getDate").getDate()).slice(-2).toString();
                        
                        if(parseInt(dataEntrada) > parseInt(dataPartida)) {
                            $(".data-partida").text(date);
                        }
                    }

                    $('#data-partida').datepicker('option', 'minDate', $("#data-chegada").datepicker("getDate"));
                }
            });

            

        });

        $(function() {
            $("#data-partida").datepicker({
                dateFormat: 'dd/mm/yy',
                dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
                dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
                dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
                monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                nextText: 'Próximo',
                prevText: 'Anterior',
                dateFormat: 'dd-mm-yy',
                minDate: 0,
                onSelect: function(date) {
                    $(".data-partida").text(date);
                }
            });
            $("#data-partida").datepicker('disable');
        }); 

        const slectsDivs = document.querySelectorAll(".campo-form");
        slectsDivs.forEach(element => {
            if (element.querySelector("select")) {
                let SelectAtual = element.querySelector("select");
                SelectAtual.addEventListener("change", () => {
                    if (element.querySelector("label span")) {
                        let spanLabel = element.querySelector("label span");
                        spanLabel.textContent = "( " + SelectAtual.value + " )";
                    }
                });
            }
        });

    }

}