const allOptionMobile = document.querySelectorAll(".main-menu-mobile .main-menu-mobile__item");
const allJsCloseMenu = document.querySelectorAll(".js-close-menu-mobile");
const xicon =  document.querySelector(".burger-icon-container");

allOptionMobile.forEach((element) => {
    if(element.querySelector("ul")) {
        element.classList.remove("js-close-menu-mobile");

        element.querySelector("a").addEventListener("click", (event) => {event.preventDefault();});

        element.addEventListener("click", (event) => {
            let submenuAtual = element.querySelector("ul");

            // allOptionMobile.forEach((option) => {
            //     // option.querySelector("ul").classList.remove("--is-active");

            //     // if(element === option){
            //     //     submenuAtual.classList.add("--is-active");
            //     // }
            // });

            submenuAtual.classList.toggle("--is-active");

            // if(submenuAtual.classList.contains("--is-active")){
            //     allOptionMobile.forEach((option) => {
            //         submenuAtual.classList.remove("--is-active");
            //     });
            // } else {
            //     allOptionMobile.forEach((option) => {
            //         option.querySelector("ul").classList.remove("--is-active");
            //         // if(element === option){
            //         //     submenuAtual.classList.add("--is-active");
            //         // }
            //     });
            // }
        });
    }
});

allJsCloseMenu.forEach(element => {
    element.addEventListener("click", () => {
        xicon.classList.remove("is-active");
    });
});
